import React from 'react'

import getMediaFile from "~shared/lib/getMediaFile";
import { changeBg } from "~shared/lib/avatar";

import icon from '~img/sidebar/icon.svg';

const Logo = ({ data, admin, client_item }) => {
	return (
		<div className="logo">
			{admin ? (
				<img alt="icon" src={icon} />
			) : (
				<>
					{data.client_logo ? (
						<img alt="Logo" src={client_item ? `${window.location.origin.replace('3000', '8000')}${data.client_logo}` : getMediaFile(data.client_logo)} />
					) : (
						<div className="logo-bg bold"
							style={{
								backgroundColor: changeBg(data.id)
							}}>
							{data ? (data.client_name[0].toUpperCase()) : null}
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default Logo