import React from 'react';
import { createPortal } from 'react-dom';
import Button from "~shared/ui/Button/Button";
import { ReactComponent as CloseIcon } from '~img/icons/close.svg';

const ConfirmModal = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) onClose(false);
    };

    return createPortal(
        <div className="modal modal-confirm">
            <div className="modal__overlay" onClick={handleOverlayClick}>
                <div className="modal__content">
                    <button className="modal__close" type="button" onClick={() => onClose(false)}>
                        <CloseIcon />
                    </button>

                    <h2 className="modal__title modal-confirm__title">{message}</h2>
                    <div className="modal-confirm__buttons">
                        <Button variant="main" className="button_save" type="button" onClick={() => onConfirm()}>
                            <span className='text'>Подтвердить</span>
                            <span className='text_mobile'>Да, удалить</span>
                        </Button>
                        <Button variant="main" className="button_delete-data" type="button" onClick={() => onClose(false)}>
                            Отмена
                        </Button>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

export default ConfirmModal;
