import React from 'react';
import {Link} from 'react-router-dom';

import icon_500 from '~img/errorpage/500.svg'
import icon_404 from '~img/errorpage/404.svg'

const ErrorPage = (props) => {

    return (
        <div className='error-page__wrapper'>
            <div className="error-page__icon">
                {props.page === '404' ? (
                    <img alt={'Иконка'} src={icon_404}/>
                ) : props.page === 'sso-403' ? (
                    <img alt={'Иконка'} src={icon_404}/>
                ) : (
                    <img alt={'Иконка'} src={icon_500}/>
                )}
            </div>

            {props.page !== 'sso-403' && 
                <div className="error-page__title bold">
                    {props.page}
                </div>
            }

            {props.page === '404' ? (
                <div className="error-page__subtitle bold">
                    Такой страницы не существует
                </div>
            ) : props.page === 'sso-403' ? (
                <div className="error-page__subtitle bold">
                    У вас нет прав доступа!
                </div>
            ) : (
                <div className="error-page__subtitle bold">
                    Идут технические работы
                </div>
            )}

            {props.page === '404' ? (
                <div className="error-page__text ">
                    Проверьте адрес ссылки или перейдите на&nbsp;
                    <Link to="/" className='link'>
                        главную страницу
                    </Link>
                </div>
            ) : props.page === 'sso-403' ? (
                <div className="error-page__text ">
                    У вас нет прав доступа для захода на данный сервис. Запросите права у своего ТЛ.<br />
                    <Link to="/" className='link'>
                        Вернуться на главную страницу.
                    </Link>
                </div>
            ) : (
                <div className="error-page__text ">
                    Пожалуйста, попробуйте загрузить страницу позже
                </div>
            )}
        </div>
    )
};

export default ErrorPage;
