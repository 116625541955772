import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from "~img/icons/close.svg";
import Loader from '~shared/ui/Loader/Loader';

const Modal = ({
    className = '', 
    showModal, 
    modalLoading, 
    onClose, 
    children
}) => {
    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget && !modalLoading) {
            onClose();
        }
    };

    useEffect(() => {
        if (!showModal) return;

        const handleEscKey = (event) => {
            if (event.key === 'Escape' && !modalLoading) {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEscKey);

        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, [showModal, modalLoading, onClose]);

    if (!showModal) return null;

    const modalClasses = ['modal', className].filter(Boolean).join(' ');

    return createPortal(
        <div className={modalClasses}>
            <div className="modal__overlay" onClick={handleOverlayClick}>
                <div className="modal__content">
                    {modalLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <button className="modal__close" onClick={onClose}>
                                <CloseIcon />
                            </button>
                            {children}
                        </>
                    )}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;
