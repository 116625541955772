import { Link, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import useCheckUserGroup from '~hooks/useCheckUserGroup';


const Menu = () => {
    const location = useLocation();
    const isActive = (path) => location.pathname === path ? 'is-active' : '';
    const isAdmin = useCheckUserGroup('Администратор');
    const isManager = useCheckUserGroup('Менеджер');

    let userNameWrapper = document.querySelector('.sidebar__user'),
        sidebarInner = document.querySelector('.sidebar__li-inner'),
        sidebarWrapper = document.querySelector('.sidebar__li-wrapper_more');

    if (!!userNameWrapper) {
        if (userNameWrapper.offsetWidth < userNameWrapper.querySelector('span').offsetWidth) {
            userNameWrapper.classList.add('sidebar__user_cut')
        } else {
            userNameWrapper.classList.remove('sidebar__user_cut')
        }
    }

    useEffect(() => {
        let count = document.querySelector('.sidebar__li-wrapper').querySelectorAll('.sidebar__li').length;

        if(count > 1){
            document.querySelector('.sidebar__li-wrapper').classList.add('sidebar__li-wrapper_more')
        }
    })

    const handleClick = () => {
        document.querySelector('.sidebar__li-wrapper_more').classList.toggle('active')
        document.querySelector('.sidebar__li-inner').classList.toggle('active')
    }

    window.addEventListener('click', (e) => {
        if (!e.target.closest('.sidebar__li-wrapper_more') && sidebarInner && sidebarWrapper) {
            sidebarInner.classList.remove('active')
            sidebarWrapper.classList.remove('active')
        }
    })

    return (
        <div className="sidebar__nav-wrapper ">
            <ul className="sidebar__nav">
                <li className={`sidebar__li ${isActive('/')}`}>
                    <Link to="/" className="sidebar__li-title tab-1">Дашборд</Link>
                </li>
                <li className={`sidebar__li ${isActive('/tariff')}`}>
                    <Link
                        to="/tariff"
                        className="sidebar__li-title tab-2"
                    >
                        Тариф
                    </Link>
                </li>
                <li className={`sidebar__li ${isActive('/revenue')}`}>
                    <Link to="/revenue" className="sidebar__li-title tab-3">Выручка</Link>
                </li>
                <li className={`sidebar__li ${isActive('/sites')}`}>
                    <Link to="/sites" className="sidebar__li-title tab-4">Сайты</Link>
                </li>
                <div className='sidebar__li-wrapper'>
                    <div className="sidebar__li-title title_more" onClick={handleClick}>
                        Еще
                    </div>
                    <div className="sidebar__li-inner">
                        {/* Все вкладки после 5й прописывать сюда */}
                        <li className={`sidebar__li ${isActive('/reports')}`}>
                            <Link to="/reports" className="sidebar__li-title tab-5">Отчёты</Link>
                        </li>
                        {(isAdmin || isManager) && (
                            <li className={`sidebar__li ${isActive('/reports-edit')}`}>
                                <Link to="/reports-edit" className="sidebar__li-title tab-5">Редактирование
                                    отчётов</Link>
                            </li>
                        )}
                    </div>
                </div>
            </ul>
        </div>
    )
}

export default Menu
