import * as React from 'react';
import {useState} from "react";
import { IMaskInput } from 'react-imask'

const Calculate = () => {
    const [desiredRevenue, setDesiredRevenue] = useState('');
    const [calculatedVal, setCalculatedVal] = useState('');

    const handleChange = (event) => {
        const inputValue = event.replace(/\s/g, '');

        if (!isNaN(inputValue) && inputValue !== '') {
            const numericValue = Math.ceil(Number(inputValue));
            const calculated = Math.ceil(numericValue * 0.15);

            const formattedDesiredRevenue = numericValue.toLocaleString();
            const formattedCalculatedVal = calculated.toLocaleString();

            setDesiredRevenue(formattedDesiredRevenue);
            setCalculatedVal(formattedCalculatedVal);
        } else {
            setDesiredRevenue('');
            setCalculatedVal('');
        }
    };

    return (
        <div className="revenue__calc-wrapper">
            <div className="revenue__calc-title bold">
                Рассчитать РБ для желаемой выручки
            </div>
            <div className="revenue__calc-input">
                <div className="revenue__calc-input-title bold">
                    Введите желаемую выручку:
                </div>

                <IMaskInput
                    className="input bold"
                    mask={Number}
                    min={100}
                    thousandsSeparator=" "
                    value={desiredRevenue}
                    onAccept={handleChange}
                    placeholder="1 000 000"
                />
            </div>
            <div className="revenue__calc-output">
                <div className="revenue__calc-output-title bold">
                    Ориентировочный рекламный бюджет:
                </div>
                <IMaskInput
                    className="input bold"
                    mask={Number}
                    thousandsSeparator=" "
                    value={calculatedVal}
                    readOnly
                    placeholder="150 000"
                />
            </div>
            <div className="revenue__calc-info">
                Норма вложений в маркетинг (рекламный бюджет), обычно, составляет –
                <span className="bold">
                    10-15% от желаемой выручки
                </span>
            </div>
        </div>
    );
};

export default Calculate;
