import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from '~img/icons/search.svg';  // Предположим, что иконка доступна по этому пути

const SearchInput = ({ searchTerm, onSearchChange, placeholder = "Найти", className = "" }) => {
    return (
        <div className={`search ${className}`}>
            <input
                className="search-input input"
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={onSearchChange}
            />
            <img alt="icon" className="search-icon" src={searchIcon} />
        </div>
    );
};

// Указываем типы и обязательность пропсов для компонента
SearchInput.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

export default SearchInput;