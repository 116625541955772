import React, {useContext} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { DataContext } from '~shared/context/DataContext';
import axios from "~shared/api/axiosSetup";

const PrivateRoute = ({ children }) => {
    const { user, setData, setProfile} = useContext(DataContext);
    const isAuthenticated = !!user;
    const navigate = useNavigate();

    const userLogout = async (user_id) => {
        try {
            let response = await axios.get(
                `${window.location.origin.replace('3000', '8000')}/api/logout/${user_id}`
            );

            if (response.status === 204) {
                localStorage.removeItem('user');
                sessionStorage.removeItem('currentProfile');
                setData(null)
                setProfile(null)
                navigate('/login');
            }
        } catch (error) {
            console.error(error)
        }
    }

    if (isAuthenticated) {
        userLogout(user.id)
    }
    
    let currentLocalProfile = sessionStorage.getItem('currentProfile');

    if (!currentLocalProfile && isAuthenticated && user.is_staff && user.is_active) {
        return <Navigate to="/clients" />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
