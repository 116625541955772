export function isWorked() {
    let date = new Date();
    let moscowDate = new Date(date.toLocaleString("en-US", {timeZone: "Europe/Moscow"}));
    return moscowDate.getHours() >= 9 && moscowDate.getHours() < 18;
}

export function nowDate() {
    let date = new Date();
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}.${date.getFullYear()}`;
}

export function nowYear(param = 0) {
    let date = new Date();
    return `${date.getFullYear() + param}`
}

export function nowMonth() {
    let date = new Date();
    return `${date.toLocaleString("ru", {month: 'long'})} ${date.getFullYear()}`
}

export function firstMonth() {
    let date = new Date();
    date.setMonth(0);
    let month = date.toLocaleString("ru", {month: 'long'})
    return ` ${month[0].toUpperCase() + month.slice(1)}`
}

export function previousMonth() {
    let date = new Date();
    date.setDate(0);
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}.${date.getFullYear()}`;
}

// Переводит дату в формат mount, year из формата YYYY-MM-DD
export function formatDateMountYear(param) {
    const dateObject = new Date(param);
    const options = { month: 'long', year: 'numeric' };
    let monthYear = dateObject.toLocaleDateString('ru-RU', options);
    let [month, year] = monthYear.split(' ');
    if (month.length > 4) {
        month = month.substring(0, 3) + '.';
    }
    const formattedDate = `${month}, ${year}`;
    return formattedDate.replace(' г.', '');
}

// Переводит дату в формат DD.MM.YYYY из формата YYYY-MM-DD
export function formatDateDDMMYYYY(dateStr) {
    const [yarn, mount, day] = dateStr.split('-')

    return `${day}.${mount}.${yarn}`
}

// Выводит период для отчётов в формате DD.MM-DD.MM.YY
export const reportPeriod = (periodStart, periodEnd) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
    };

    const periodStartFormatted = formatDate(periodStart);
    const periodEndFormatted = formatDate(periodEnd);
    const year = String(new Date(periodEnd).getFullYear()).slice(-2);

    return `${periodStartFormatted} - ${periodEndFormatted}.${year}`;
};

// Выводит дату в формате YYYY-MM-DD для сохранения в базу
export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// Форматирует число в формате 999 999 999
export function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatNumberToText() {
    const formatter = new Intl.DateTimeFormat('ru', { month: 'long' });
    return formatter.format(new Date());
}
