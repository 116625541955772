import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '~shared/context/DataContext';
import Contacts from "~shared/ui/Contacts/Contacts";
import Graph from "~shared/components/Graph/Graph";
import Loader from "~shared/ui/Loader/Loader";
import axios from "~shared/api/axiosSetup";
import { nowDate, previousMonth, formatNumber, nowMonth, formatNumberToText } from "~shared/lib/date";
import getApiData from "~shared/api/getApiData";

import { ReactComponent as IconArrow } from '~img/dashboard/icon.svg';

const Dashboard = () => {
    const { data, profile, service } = useContext(DataContext);
    const changeServices = profile.services.length
    const allServices = service.length
    const [budget, setBudget] = useState([]);
    const [finance, setFinance] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentMonthBudget, setCurrentMonthBudget] = useState(null);

    let lastProfit,
        diffProfit;

    useEffect(() => {
        if (profile.id) {
            getApiData(`budget/profile/${profile.id}`, (fetchedData) => {
                setBudget(fetchedData[0])
                setFinance(fetchedData[1])
                setLoading(false);
            }, setLoading);
        } else {
            setLoading(false);
        }
    }, [profile]);

    useEffect(() => {
        const fetchBudget = async () => {
            try {
                const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/change-revenue/${profile.id}`);
                if (response.status === 200) {
                    const fetchedBudget = response.data;
                    const today = new Date();
                    const currentYear = today.getFullYear();
                    const currentMonth = today.getMonth() + 1;

                    const filteredCurrentMonthBudget = fetchedBudget.find(
                        (item) => item.year === currentYear && item.month === currentMonth
                    );

                    setCurrentMonthBudget(filteredCurrentMonthBudget ? filteredCurrentMonthBudget.budget_amount : null);
                } else {
                    console.error('Ошибка при загрузке данных бюджета');
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных бюджета:', error);
            }
        };

        if (profile.id) {
            fetchBudget();
        }
    }, [profile.id]);

    let profitList = []

    for (let key in finance) {
        let item = finance[key];
        profitList.unshift(+item.revenue)
    }

    if (Number(profitList[profitList.length - 1]) !== 0) {
        lastProfit = (profitList[profitList.length - 1] / 1000000).toFixed(2)
        diffProfit = ((profitList[profitList.length - 1] / 1000000 - profitList[profitList.length - 2] / 1000000)).toFixed(2)
    }

    return (
        <div>

            {loading ? (
                <div className="content__wrapper content__wrapper_loader">
                    <Loader />
                </div>
            ) : (
                <div className="dashboard__wrapper">
                    <React.Fragment>
                        <Link to="/tariff" className="dashboard__tariff dashboard__item dashboard__item_link">
                            <div className="dashboard__arrow">
                                <IconArrow />
                            </div>
                            <div className="dashboard__elem-title">
                                Ваш тариф
                            </div>
                            <div className="tariff__info-include">
                                <div className="tariff__info-include-title">
                                    Включено услуг:
                                </div>
                                <div className="tariff__info-include-count bold">
                                    <span className={`tariff__info-include-span ${Number(changeServices) === 0 ? "bold_grey" : ''}`}> {changeServices} </span>
                                    из {allServices}
                                </div>
                                <div className="tariff__info-include-line">
                                    <div className="bg">

                                    </div>
                                    <div className="line"
                                        style={{
                                            width: 100 / (allServices) * changeServices + '%',
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className="tariff__info-price-wrapper">
                                <div className="tariff__info-price-title">
                                    Стоимость за&nbsp;{formatNumberToText()}:
                                </div>
                                <div className={`tariff__info-price bold ${!data.tariff_price ? "bold_grey" : ''}`}>
                                    {data.tariff_price ? `${formatNumber(data.tariff_price)} р.` : "0 р."}
                                </div>
                            </div>
                        </Link>
                        <Link to="/revenue" className="dashboard__balance dashboard__item dashboard__item_link">
                            <div className="dashboard__arrow">
                                <IconArrow />
                            </div>
                            <div className="dashboard__elem-title">
                                Рекламный бюджет
                            </div>
                            <div className="revenue__plan">
                                Планируемый РБ
                                {Number(currentMonthBudget) !== 0 && currentMonthBudget !== null ? (
                                    <>
                                        на {nowMonth()}:
                                        <span className="span bold">{formatNumber(currentMonthBudget)} р.</span>
                                    </>
                                ) : (
                                    <>
                                        :<span className="span bold bold_grey">0 р.</span>
                                    </>
                                )}
                            </div>
                            <div className="revenue__balance">
                                Остаток
                                {budget && budget.budget ? (
                                    <>
                                        &nbsp;на {nowDate()}:
                                        <span className="span bold">{formatNumber(budget.budget)} р.</span>
                                    </>
                                ) : (
                                    <>
                                        :<span className="span bold bold_grey">0 р.</span>
                                    </>
                                )}
                            </div>
                        </Link>
                        <Link to="/revenue" className="dashboard__profit dashboard__item dashboard__item_link">
                            <div className="dashboard__arrow">
                                <IconArrow />
                            </div>
                            <div className="dashboard__elem-title">
                                Выручка
                            </div>
                            {!isNaN(lastProfit) || !isNaN(diffProfit) ? (
                                <>
                                    <div className="dashboard__profit-text">
                                        по состоянию на {nowDate()}
                                    </div>
                                    <div className="dashboard__profit-calc">
                                        {!isNaN(lastProfit) &&
                                            <span className="span bold">{lastProfit} млн.р.</span>
                                        }
                                        {!isNaN(diffProfit) &&
                                            <React.Fragment>
                                                {diffProfit >= 0 ?
                                                    (<span className="diff">+{diffProfit} млн.р.</span>) :
                                                    (<span className="diff negative">{diffProfit} млн.р.</span>)
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="dashboard__profit-text-bottom">
                                        по сравнению с предыдущим месяцем
                                    </div>
                                </>
                            ) : (
                                <div className="dashboard__not__data-text">
                                    Нет данных
                                </div>
                            )}
                        </Link>
                        <div className="dashboard__avd dashboard__item">
                            <div className="dashboard__elem-title">
                                ДРР (Доля рекламных расходов)
                            </div>
                            {budget && budget.drr ? (
                                <div className="dashboard__avd-text">
                                    <span className="span bold">{budget.drr} %</span>
                                    по состоянию на {previousMonth()}
                                </div>
                            ) : (
                                <div className="dashboard__not__data-text">
                                    Нет данных
                                </div>
                            )}
                        </div>

                        <div className="dashboard__contacts-wrapper">
                            <Contacts page={'dashboard'} />
                        </div>
                        <Link to="/reports" className="dashboard__reports dashboard__item dashboard__item_link">
                            <div className="dashboard__arrow">
                                <IconArrow />
                            </div>
                            <div className="dashboard__elem-title">
                                Отчёты
                            </div>
                        </Link>
                        {(budget?.pbi_link &&
                            <a href={budget.pbi_link} target='_blank' rel="noreferrer" className="dashboard__analytics dashboard__item dashboard__item_link">
                                <div className="dashboard__analytics-title">
                                    Сквозная аналитика Статум
                                </div>
                                <div className="dashboard__analytics-link link bold">
                                    Перейти в Статум
                                </div>
                            </a>
                        )}
                        <div className="dashboard__graph-wrapper dashboard__item">
                            <Graph budget={budget ? budget : []} finance={finance} />
                        </div>
                    </React.Fragment>
                </div>
            )}
        </div>
    )
}

export default Dashboard
