import React, { useState, createRef, useEffect, useContext } from 'react';
import axios from "~shared/api/axiosSetup";
import { useNavigate } from 'react-router-dom';
import { DataContext } from '~shared/context/DataContext';
import Button from '~shared/ui/Button/Button';

import Logo from '~img/login/logo.svg';
import EyeOff from '~img/login/eyeOff.svg';
import EyeOn from '~img/login/eyeOn.svg';

const Login = () => {
    const { setUser } = useContext(DataContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(EyeOff);
    const navigate = useNavigate();
    const passwordInputRef = createRef();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/user-data/`);
                if (response.data.user) {
                    setUser(response.data.user);
                }

                navigate('/');
            } catch (error) {
                console.log('Пользователь не авторизован')
            }
        };

        fetchUserData()
    }, [navigate, setUser]);

    const showHidePass = () => {
        if (type === 'password') {
            setIcon(EyeOn);
            setType('text');
        } else {
            setIcon(EyeOff);
            setType('password');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/login/`, { email, password });

            setUser(response.data.user);
            navigate('/');  // Перенаправление на главную страницу после успешного входа
        } catch (error) {
            setError(error.response.data.error)
        }
    };

    return (
        <div className="login__wrapper">
            <div className="login">
                <div className="login__logo">
                    <img alt="logo" src={Logo} />
                </div>

                <h1 className="login__title">Вход в личный кабинет</h1>

                { error &&
                    <div className="status__block">
                        <span>{error}</span>
                    </div>
                }

                <form className="login__form" onSubmit={handleSubmit}>
                    <div className="form__group form__group_name">
                        <label className="label">Адрес электронной почты:</label>
                        <input
                            className={`input ${error ? 'error' : ''}`}
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="example@mail.ru"
                        />
                    </div>
                    <div className="form__group">
                        <label className="label">Пароль:</label>
                        <div className="input_wrapper">
                            <input
                                ref={passwordInputRef}
                                className={`input ${error ? 'error' : ''}`}
                                type={type}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type="button" className="input__show__password" onClick={showHidePass}>
                                <img alt="logo" className="input__show__password-icon" src={icon} />
                            </button>
                        </div>
                    </div>
                    <Button variant="main" className="login__button" type="submit">
                        Войти
                    </Button>
                </form>
                <div className="login__help">
                    Есть сложности при входе?<br />
                    Свяжитесь со своим менеджером
                </div>
            </div>
        </div>
    );
};

export default Login;
