import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'

import Logout from '~shared/components/Logout/Logout';
import Logo from '~shared/components/Logo/Logo'

export default function ProfilePage() {
    const navigate = useNavigate();
    const location = useLocation()
    let data = location.state.data,
        user = location.state.user

    return (
        <div className='profile-page__wrapper'>
            <button onClick={() => navigate(-1)} className='profile-page__link bold'> Назад </button>
            <Logo data={data} />
            {data.client_name && (
                <div className="profile-page-title">
                    <span>
                        {data.client_name}
                    </span>
                </div>)}
            <div className="profile-page-data">
                {user.email && (
                    <a href={user ? `mailto:${user.email}` : null} className="profile-page-mail">
                        {user.email}
                    </a>
                )}
            </div>
            <Logout to_clinics={true} />
            {/* <div className="profile-page__help">
                Обратиться
                в&nbsp;техподдержку
            </div> */}
            <div className="profile-page__copyright">
                <a href="https://sinergium.ru" rel="noreferrer" target="_blank">
                    Сделано в Синергиум
                </a>
            </div>
            <Logout profile={true} />
        </div>
    )
}
