import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import Logout from '~shared/components/Logout/Logout';
import getApiData from '~shared/api/getApiData';
import Menu from "~shared/components/Menu/Menu";
import { DataContext } from '~shared/context/DataContext';
import Loader from '~shared/ui/Loader/Loader';
import Logo from '~components/Logo/Logo';

function Home() {
    const navigate = useNavigate();
    const { data, setData, setProfile, setService, user } = useContext(DataContext);
    const [loading, setLoading] = useState(true);
    const location = useLocation()

    useEffect(() => {
        const fetchData = async () => {
            if (user) {

                let profile_id;
                if (sessionStorage.getItem("currentProfile")) {
                    profile_id = sessionStorage.getItem("currentProfile");
                } else {
                    profile_id = user.username;
                }

                try {
                    // Устанавливаем загрузку перед выполнением запросов
                    setLoading(true);

                    // Выполняем запросы
                    await getApiData("services/", setService, setLoading, navigate);
                    await getApiData(`profile/${profile_id}`, (data) => {
                        setProfile(data[0]);
                    }, setLoading, navigate);
                    await getApiData(`client_data/${profile_id}`, (data) => {
                        setData(data[0]);
                    }, setLoading, navigate);
                } catch (error) {
                    console.error("Ошибка при загрузке данных:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchData();

    }, [user, navigate, setService, setProfile, setData]);

    if (loading || !user) {
        return (
            <div className="home__wrapper">
                <div className="sidebar__wrapper">
                    <div className="sidebar__container">
                        <Logout />
                    </div>
                </div>
                <div className="content__wrapper content__wrapper_loader">
                    <Loader />
                </div>
            </div>
        )
    }

    if (data && user) {
        return (
            <div className={`home__wrapper ${location.pathname === '/profile' ? 'home__wrapper_profile-page' : ''}`}>
                <div className="sidebar__wrapper">
                    <div className="sidebar__container">
                        <Logout />
                        <div className="sidebar__user-wrapper">
                            <Link className='sidebar__user-link' to={'/profile'} state={{ data, user }}>
                                <Logo data={data} />
                            </Link>
                            <Link className='sidebar__user-link text' to={'/profile'} state={{ data, user }}>
                                {data.client_name && (
                                    <div className="sidebar__user">
                                        <span>
                                            {data.client_name}
                                        </span>
                                    </div>
                                )}
                            </Link>
                        </div>
                        <Menu />
                        <Logout to_clinics={true} />
                        {/* <div className="sidebar__help">
                            Обратиться
                            в&nbsp;техподдержку
                        </div> */}
                        <div className="sidebar__copyright">
                            <a href="https://sinergium.ru" rel="noreferrer" target="_blank">
                                Сделано в Синергиум
                            </a>
                        </div>
                        <div className="sidebar__profile-wrapper">
                            <Link className='sidebar__profile' to={'/profile'} state={{ data, user }}>
                                <div className="sidebar__profile-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M2.41602 15.6877C2.41602 14.682 2.81549 13.7176 3.52657 13.0065C4.23764 12.2955 5.20207 11.896 6.20768 11.896H13.791C14.7966 11.896 15.7611 12.2955 16.4721 13.0065C17.1832 13.7176 17.5827 14.682 17.5827 15.6877C17.5827 16.1905 17.3829 16.6727 17.0274 17.0282C16.6719 17.3838 16.1897 17.5835 15.6868 17.5835H4.31185C3.80904 17.5835 3.32683 17.3838 2.97129 17.0282C2.61575 16.6727 2.41602 16.1905 2.41602 15.6877Z" stroke="#737B8D" strokeWidth="1.42188" strokeLinejoin="round" />
                                        <path d="M9.99902 8.10449C11.5696 8.10449 12.8428 6.8313 12.8428 5.26074C12.8428 3.69018 11.5696 2.41699 9.99902 2.41699C8.42846 2.41699 7.15527 3.69018 7.15527 5.26074C7.15527 6.8313 8.42846 8.10449 9.99902 8.10449Z" stroke="#737B8D" strokeWidth="1.42188" />
                                    </svg>
                                </div>
                                <span className='sidebar__profile-text'>В профиль</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="content__wrapper">
                    <Outlet />
                </div>
            </div>
        );
    } else {
        return (
            <div className="content__wrapper content__wrapper_loader">
                <Loader />
            </div>
        )
    }
}

export default Home;
