import React from 'react'
import Button from '~shared/ui/Button/Button';

import Logo from '~img/login/logo.svg';

const getUser = (event) => {
    event.preventDefault();
    sessionStorage.removeItem('currentProfile');
    window.location = `${window.location.origin.replace('3000', '8000')}/login-sso/admin/`;
};

const LoginSSO = () => {
    return (
        <div className={"login__wrapper"}>
        <div className={"login"}>
            <div className={"login__logo"}><img alt={"logo"} src={Logo} /></div>
            <h1 className={"login__title"}>Вход в личный кабинет через SSO</h1>

            <Button variant="main" className='login__button' onClick={getUser}>Войти</Button>
            <div className="login__help">
                Есть сложности при входе?<br />
                Свяжитесь с отделом разработки
            </div>
        </div>
    </div>
    )
}

export default LoginSSO
