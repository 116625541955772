import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '~shared/context/DataContext';
import useCheckUserGroup from '~hooks/useCheckUserGroup';

import logoutIco from '~img/logout/logout-icon.svg'


const Logout = ({ hide, to_clinics, profile }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { user, setData, setProfile } = useContext(DataContext);
    const isAdmin = useCheckUserGroup('Администратор');
    const isManager = useCheckUserGroup('Менеджер');

    const handleLogout = async (event) => {
        event.preventDefault();
        try {
            await axios.post(`${window.location.origin.replace('3000', '8000')}/api/logout/`);
            localStorage.removeItem('user');
            sessionStorage.removeItem('currentProfile');
            setData(null)
            setProfile(null)
            navigate('/login');
        } catch (error) {
            setError('Ошибка выхода из аккаунта');
        }
    };

    const handleClientProfile = async (event) => {
        event.preventDefault();
        try {
            sessionStorage.removeItem('currentProfile');
            setData(null)
            setProfile(null)
            navigate('/clients');
        } catch (error) {
            setError('Ошибка выхода из профиля');
        }
    };

    return (
        <>
            {!to_clinics ? (
                <div className="logout__wrapper" onClick={handleLogout}>
                    <button type="button" className={`logout__button ${error ? 'error' : ''}`} >
                        <img alt="logout-icon" className="logout__icon" src={logoutIco} />
                    </button >

                    <span className="logout__text">
                        {!profile ? (
                            <>
                                {!user.first_name && !user.last_name ? user.username : user.first_name + ' ' + user.last_name}
                            </>
                        ) : (
                            'Выйти из аккаунта'
                        )}
                    </span>
                </div >
            ) : (
                <>
                    {!hide && (isAdmin || isManager) && (
                        <button type="button" className="logout__button_bottom"
                            onClick={handleClientProfile}>
                            <div className="logout__button-icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5625 8.125H19.5M10.5625 13H19.5M10.5625 17.875H19.5M7.3125 8.125V8.13313M7.3125 13V13.0081M7.3125 17.875V17.8831" stroke="#737B8D" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <span className='logout__button-text'>Выбор клиники</span>
                        </button>
                    )}
                </>
            )}
        </>

    )
};

export default Logout;
