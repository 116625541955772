import React from 'react';
import PropTypes from 'prop-types';
import Loader from "~shared/ui/Loader/Loader";

const Button = ({
    onClick = () => {},
    children,
    type = 'button',
    className = '',
    disabled = false,
    variant = 'main',
    href = '', 
    icon = null,
    target = '_self',
    loading = false,
}) => {
    const buttonClasses = [
        'button',
        `button_${variant}`,
        icon ? `button_icon` : '',
        className
    ].filter(Boolean).join(' ');

    const renderIcon = () => {
        if (!icon) return null;
        return <img src={icon} alt="" className="button__icon" />;
    };

    if (href) {
        return (
            <a
                href={href}
                className={buttonClasses}
                onClick={onClick}
                role="button"
                aria-disabled={disabled}
                target={target}
            >
                {renderIcon()}

                {loading ? <Loader/> :
                        <span className="button__text">
                            {children}
                        </span>
                }
            </a>
        );
    }

    return (
        <button
            onClick={onClick}
            type={type}
            className={buttonClasses}
            disabled={disabled}
        >
            {renderIcon()}

            {loading ? <Loader/> :
                    <span className="button__text">
                        {children}
                    </span>
            }
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(['button', 'submit']),
    variant: PropTypes.oneOf(['main', 'border', 'white']),
    target: PropTypes.oneOf(['_self', '_blank']),
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    icon: PropTypes.node,
};

export default Button;
