import React from 'react';
import uploadIcon from '~img/icons/upload.svg';
import settingIcon from '~img/icons/setting.svg';
import deleteIcon from '~img/icons/delete-icon.svg';

import Logo from '~shared/components/Logo/Logo'


const ClientItem = ({ profile, getClientProfile, openModal, fileChange, access }) => {
    const openMenu = (e) => {
        let wrapper = e.currentTarget,
            allWrappers = document.querySelectorAll('.clients__controls-wrapper.open'),
            open = wrapper.classList.contains('open');

        allWrappers.forEach(elem => {
            elem.classList.remove('open')
            elem.querySelector('.clients__show-more').classList.remove('open')
            elem.querySelector('.clients__controls-inner').style.transform = `none`
        })
        if (open && (e.target.classList.contains('clients__show-more') || !!e.target.closest('.clients__item-icon'))) {
            wrapper.classList.remove('open')
            wrapper.querySelector('.clients__show-more').classList.remove('open')
            wrapper.querySelector('.clients__controls-inner').style.transform = `none`
        } else {
            wrapper.classList.add('open')
            wrapper.querySelector('.clients__show-more').classList.add('open')
            let position = wrapper.querySelector('.clients__controls-inner').getBoundingClientRect().left

            if (position < 10) {
                wrapper.querySelector('.clients__controls-inner').style.transform = `translateX(${(position < 0 ? -position : 5) + 10}px)`
            }
        }
    }

    return (
        <div key={profile.id} className={`clients__item ${!!profile.client_active ? 'active' : 'not-active'} ${access ? "access" : "not-access"}`}>
            <div className="clients__item-logo" onClick={() => getClientProfile(profile.client_name)}>
                <Logo data={profile} client_item={true} />

            </div>
            <div className="clients__item-info" onClick={() => getClientProfile(profile.client_name)}>
                {profile.client_name && <div className="clients__item-name bold">{profile.client_name}</div>}
                {!!profile.client_active ? (
                    <div className="clients__item-status bold active">активный</div>
                ) : (
                    <div className="clients__item-status bold">неактивный</div>
                )}
            </div>
            <div className="clients__controls-wrapper" onClick={(e) => { openMenu(e) }}>
                <button type="button" className="clients__item-upload-icon clients__show-more">
                </button>
                <div className="clients__controls-inner">
                    {profile.client_logo && (
                        <div className="clients__item-icon clients__item-delete-icon" title="Удалить логотип?">
                            <button type="button" onClick={() => fileChange.delete(profile.id)} className="clients__item-upload-icon">
                                <div className='img-wrapper'><img src={deleteIcon} alt="Удаление логотипа" /></div>
                                <span className='bold'>Удалить логотип</span>
                            </button>
                        </div>
                    )}
                    <div className="clients__item-icon clients__item-setting" title="Редактировать доступы?">
                        <button type="button" onClick={() => openModal(profile.user)} className="clients__item-upload-icon">
                            <div className='img-wrapper'><img src={settingIcon} alt="Настройка" /></div>
                            <span className='bold'>Редактировать данные для входа</span>
                        </button>
                    </div>
                    <div className="clients__item-icon clients__item-upload" title="Загрузить логотип?">
                        <label className="clients__item-upload-icon">
                            <div className='img-wrapper'><img src={uploadIcon} alt="Загрузка" /></div>
                            <span className='bold'>Загрузить логотип</span>
                            <input
                                type="file"
                                name="file"
                                id={profile.id}
                                className="upload__input"
                                onChange={fileChange.uploaded}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientItem;
