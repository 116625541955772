import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import Avatar from '~shared/components/Avatar/Avatar';
import Button from '~shared/ui/Button/Button';
import { reportPeriod } from '~shared/lib/date';

import { ReactComponent as IconCalendar } from '~img/reports/icon-calendar.svg';

export default function ReportPage() {
    const location = useLocation()

    useEffect(() => {
        document.querySelector('body').scrollTop = 0
    });

    if (!location.state) return <Navigate to="/reports" />;

    const { selectedReport, id } = location.state
    
    return (
        <div className='report-page__wrapper'>
            <HashLink to={`/reports/#${id}`} className='report-page__link bold'> Назад </HashLink>
            <div className="report-page__inner">
                <div className="report-page__data">
                    <div className="report-page__icon">
                        <IconCalendar />
                    </div>
                    <div className="report-page__title">
                        {reportPeriod(selectedReport.period_start, selectedReport.period_end)}. {selectedReport.type.title}
                    </div>
                </div>

                <div className="report-page__text">
                    {selectedReport.body.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </div>

                <div className="report-page__footer">
                    <Avatar manager={selectedReport.manager} title={`С уважением, ваш менеджер:`} />
                    <Button href="tg://resolve?domain=" variant="border" className='report-page__button'>
                        Связаться с менеджером
                    </Button>
                </div>
            </div>
        </div>
    );
}
