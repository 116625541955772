import React, { useState, useEffect, createContext } from 'react';

// Создаем контекст
export const DataContext = createContext();

export const DataProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    const [data, setData] = useState(null);
    const [profile, setProfile] = useState(null);
    const [service, setService] = useState(null);
    const [storeProfile, setStoreProfile] = useState(null);
    const [revenue, setRevenue] = useState(null);

    useEffect(() => {
        if (user !== null) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    return (
        <DataContext.Provider value={{ user, setUser, data, setData, profile, setProfile, storeProfile, setStoreProfile, service, setService, revenue, setRevenue }}>
            {children}
        </DataContext.Provider>
    );
};
